import React from "react"
import Layout from "../components/layout"
import {
  Divider,
  Stack,
  Text,
  Heading,
  Flex,
  Button,
  Link,
 
} from "@chakra-ui/core"
import Video from "../components/video"

const IndexPage = () => (
  <Layout>
    <Stack textAlign="center" my={6}>
      <Heading size="2xl" textAlign="center" my={6}>
        Success!
      </Heading>
      <Text fontSize="lg">You should receive an email within 5 minutes.</Text>
    </Stack>
    {/* <Flex justify="center">
      <Video videoSrcURL="https://www.youtube.com/embed/uJ_FRHflZAY" videoWidth={560}/>
    </Flex> */}
    <Stack textAlign="center" my={6}>
      <Button
        style={{ textDecoration: 'none' }}
        as={Link}
        href="https://mvp-secrets.com/mvp-secrets.pdf"
        mx={"auto"}
        w={300}
        mt={4}
        variantColor="green"
        type="submit"
        fontWeight="bold"
        py={8}
        fontSize="2xl"
      >
        Download PDF
      </Button>
    </Stack>
    <Divider borderColor="gray.400" borderWidth="1px" mx={4} />
  </Layout>
)

export default IndexPage
